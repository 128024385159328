import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const TiendasOnlinePage = () => {
    const data = useStaticQuery(graphql`
    query {
      tiendasOnlineImage1: file(relativePath: { eq: "images/sections/marketing-digital/tiendas-online/razones-ecommerce-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      tiendasOnlineImage2: file(relativePath: { eq: "images/sections/marketing-digital/tiendas-online/como-sera-tu-tienda-online-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      tiendasOnlineImage3: file(relativePath: { eq: "images/sections/marketing-digital/tiendas-online/expertos-tiendasonline-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Tiendas Online"
                description="Agencia de Marketing Digital en Barcelona experta en diseño de tiendas online en Woocommerce y Prestashop. Ecommerce optimizados para todos los dispositivos."
            />

            <BannerPage
                title="Agencia diseño tiendas online &amp; Ecommerce"
                text="Comercializa tus productos y servicios en Internet"
                color="green"
            />

            <SectionTextImage
                title1="Las 4 razones "
                title2="del ecommerce"
                text={<Fragment>
                    <ol class="text-left">
                        <li><strong>Abierto las 24h de día</strong>: No entiende de horarios ya que es la plataforma actual por excelencia que no tiene pausa.</li>
                        <li><strong>No está sujeto a limitaciones geográficas</strong>, en este caso el límite lo marca uno mismo.</li>
                        <li>Permite <strong>incrementar las ventas</strong>, por lo tanto aumento de facturación.</li>
                        <li><strong>Apta para todo tipo de negocios</strong>: Tanto B2B como B2C.</li>
                    </ol>
                </Fragment>}
                image={data.tiendasOnlineImage1.childImageSharp.fluid}
                imageAlt="4 razones del ecommerce - Agencia Tiendas Online"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="¿Cómo será "
                title2="tu tienda online?"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Diseño <strong>atractivo</strong> y <strong>personalizado</strong>.</li>
                        <li><strong>Intuitiva</strong>, fácil de navegar, <strong>rápida</strong> y dinámica.</li>
                        <li><strong>Funcional y autogestionable</strong>: control de clientes, stock, facturación.</li>
                        <li><strong>Responsive Web Design</strong> (mobile, tablets &amp; desktop)</li>
                        <li>Programada con una <strong>plataforma de código abierto</strong> como Prestashop, Magento y WooCommerce.</li>
                        <li><strong>Segura</strong> y fiable con certificado SSL.</li>
                        <li><strong>Optimizada</strong> para el SEO (Google, Yahoo, Bing).</li>
                        <li><strong>Multi-idioma, multi-moneda y multi-producto</strong>.</li>
                        <li>Enfocadas a la <strong>conversión</strong>.</li>
                    </ul>
                </Fragment>}
                image={data.tiendasOnlineImage2.childImageSharp.fluid}
                imageAlt="¿Cómo será tu tienda digital? - Agencia Tiendas Online"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Expertos en "
                title2="tiendas online"
                text={<Fragment>
                    En IBX ofrecemos <strong>asesoramiento global personalizado para la creación de tiendas virtuales</strong>. No hay ningún comercio electrónico igual. Nos adaptamos a las necesidades de nuestros clientes. <br /><br />
                    ¿Por qué no modernizarse y aumentar tu zona y público habitual de venta? o ¿Por qué no abrir un negocio solo online?

                </Fragment>} image={data.tiendasOnlineImage3.childImageSharp.fluid}
                imageAlt="Expertos en ecommerce - Agencia Tiendas Online"
                color="green"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="green"
                silverText="Ideal para pequeñas tiendas que quieran vender sus productos"
                silverPrice="1600"
                silverPriceCurrency="€"
                silverFeatures={["Instalación Woocommerce o Prestashop", "Plantilla y editor personalizado", "Responsive Design", "Hasta 50 productos", "Optimización de imágenes", "1 idioma", "Instalación de modulos y temas básicos", "Optimización SEO básica", "Instalación pasarela de pago básica"]}
                silverException={["* Los contenidos y los productos deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de la herramienta no incluida. Coste 150€"]}
                goldText="Ideal para tiendas con una amplia cartera de productos"
                goldPrice="2500"
                goldPriceCurrency="€"
                goldFeatures={["Instalación Woocommerce o Prestashop", "Plantilla y editor personalizado", "Responsive Design", "Hasta 200 productos", "Optimización de imágenes", "2 idiomas", "Instalación de modulos y temas avanzados", "Optimización SEO avanzada", "Instalación pasarela de pago avanzada"]}
                goldException={["* Los contenidos y los productos deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de la herramienta no incluida. Coste 150€"]}
                diamondText="Ideal para amplia cartera de productos y cobertura de mercado"
                diamondPrice="3500"
                diamondPriceCurrency="€"
                diamondFeatures={["Instalación Woocommerce o Prestashop", "Plantilla y editor personalizado", "Responsive Design", "Hasta 1000 productos", "Optimización de imágenes", "3 idiomas", "Instalación de modulos y temas premium", "Optimización SEO premium", "Instalación pasarela de pago avanzada"]}
                diamondException={["* Los contenidos y los productos deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de la herramienta no incluida. Coste 150€"]}
            />

            <CallToAction
                color="green"
                title="¿Necesitas un ecommerce para vender tus productos?"
                text={<Fragment>
                    ¡Somos la <strong>agencia de creación de tiendas online en Barcelona</strong> que necesitas! <br />
                </Fragment>}
                button="¡Lanza tu tienda online ahora!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default TiendasOnlinePage
